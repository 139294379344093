import { NationalYouthCouncilBizsafeLevel_2RiskManagementOutdoorAdventureEducationTemplate } from './bizsafe-level-2-risk-management-outdoor-adventure-education.template'

export const nationalYouthCouncilBizsafeLevel_2RiskManagementOutdoorAdventureEducationTemplates =
    [
        {
            id: 'nationalYouthCouncilBizsafeLevel_2RiskManagementOutdoorAdventureEducation',
            label: 'Certificate',
            template:
                NationalYouthCouncilBizsafeLevel_2RiskManagementOutdoorAdventureEducationTemplate,
        },
    ]
